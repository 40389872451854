<template>
    <b-modal
        v-if="value != null"
        @hidden="$emit('input', null)"
        :visible="value != null"
        :title="value.headline"
        centered 
        ok-disabled 
        cancel-disabled 
        hide-footer
    >
        <cookie-overlay
            :href="videoUrl"
            style="min-height:15em"
        >
            <b-embed
                v-if="$root.allowCookies"
                type="iframe"
                aspect="16by9"
                :src="videoUrl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
            />
        </cookie-overlay>
        <p>{{value.description}}</p>
    </b-modal>
</template>

<script>
import { BModal, BEmbed } from "bootstrap-vue"
import CookieOverlay from '@/components/CookieOverlay'
export default {
    name: "YoutubeModal",
    components:{ BModal, BEmbed, CookieOverlay },
    props: {
        value: Object
    },
    computed:{
        videoUrl: function(){
            return `https://www.youtube.com/embed/${this.value.videoId}`;
        }
    },

}
</script>