<template>
    <div class="container">
        <h1 class="mt-4">Videos</h1>
        <hr>
        <b-container> 
            <b-row
                cols-xl="4"
                cols-lg="3"
                cols-md="2"
                cols-sm="1" 
                cols="1"
            >
                <b-col
                    v-for="video in videoData"
                    :key="video.videoId"
                    @click="selectedVideo = video"
                    class="mb-2"
                >
                    <youtube-thumbnail
                        :videoId="video.videoId"
                        :headline="video.headline"
                        :description="video.description"
                    />
                </b-col>
            </b-row>
        </b-container>
        <youtube-modal v-model="selectedVideo"/>
    </div>
</template>

<script>
import videoData from "@/assets/json/videos.json"
import YoutubeModal from '@/components/YoutubeModal'
import YoutubeThumbnail from "@/components/YoutubeThumbnail"
import { BContainer, BRow, BCol } from "bootstrap-vue"
export default {
    name: "Videos",
    components: { BContainer, BRow, BCol, YoutubeThumbnail, YoutubeModal },
    data: () => ({
        videoData,
        selectedVideo: null
    })
}
</script>