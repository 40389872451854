<template>
    <b-card 
        class="shadow-sm"
        :title="headline"
        :img-src="thumbnailUrl"
    >
        <p>{{description}}</p>
    </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue"
export default {
    name: "YoutubeThumbnail",
    components: { BCard },
    props: {
        videoId: String,
        headline: String,
        description: String,
    },    
    computed: {
        thumbnailUrl: function() {
            return `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`;
        }
    },
}
</script>

<style scoped>
    div{
        cursor: pointer
    }
</style>